import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

import { Board, Event, JobStatus } from '../../../generated/types';

import { formatDate } from '../../../utils/date';
import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';

import { OfficeBuildingIcon, ViewIcon } from '../../../components/atoms/Icons';

const JobsCalendar: React.FC<any> = ({ board, handleCardDrag, handleDateSelect }: JobsCalendarProps) => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (board) {
      setEvents(board.events || []);
    }
  }, [board]);

  const formattedEvents = events.map((event: Event) => {
    return {
      id: event.id,
      title: event.description,
      start: new Date(event.startAt),
      end: new Date(event.endAt),
      allDay: false,
      jobTitle: event?.job?.jobTitle,
      jobStatus: event?.job?.status,
      company: event?.job?.company,
      eventUuid: event?.uuid,
      jobUuid: event?.job?.uuid,
      boardUuid: event?.job?.boardUuid,
      userUuid: event?.job?.userUuid,
    };
  });

  const dateClick = (arg: DateClickArg) => {
    arg.jsEvent.preventDefault();
    handleDateSelect(arg);
  };

  return (
    <>
      {/* <pre>{JSON.stringify(board, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(formattedEvents, null, 2)}</pre> */}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridDay',
        }}
        editable={false}
        selectable={true}
        initialView="dayGridMonth"
        weekends={true}
        events={formattedEvents}
        eventContent={renderEventContent}
        // eventClick={eventClick}
        // dateClick={dateClick}
      />
    </>
  );
};

export default JobsCalendar;

// function handleEventClick(arg: any) {
//   console.log('handleEventClick', arg);
// }

function handleDateClick(arg: any) {
  console.log('handleDateClick', arg);
  const { dateStr } = arg;
}

function renderEventContent(eventInfo: any) {
  const { event, timeText, view } = eventInfo;
  const { title, allDay, start, end, extendedProps } = event;
  const { jobTitle, company, boardUuid, jobUuid, eventUuid, userUuid, jobStatus } = extendedProps;
  const { type } = view;

  switch (type) {
    case 'dayGridMonth':
      return (
        <MonthDate $jobStatus={jobStatus}>
          {/* <pre>{JSON.stringify(event, null, 2)}</pre> */}
          <TwoColumnsMinorInfo>
            {formatDate({
              date: start,
              format: 'time',
            })}{' '}
            - {formatDate({ date: end, format: 'time' })}
            <Open>
              <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
                <ViewIcon />
              </Link>
            </Open>
          </TwoColumnsMinorInfo>
          <Description>{title}</Description>
          <TwoColumnsMinorInfo>
            <LineWrapper>{jobTitle}</LineWrapper>
            <LineWrapper>
              <OfficeBuildingIcon />
              {company}
            </LineWrapper>
          </TwoColumnsMinorInfo>
        </MonthDate>
      );
    case 'timeGridWeek':
      return (
        <WeekDate>
          <b>
            {formatDate({
              date: start,
              format: 'time',
            })}{' '}
            - {formatDate({ date: end, format: 'time' })}
          </b>
          <TwoColumnsMinorInfo>
            <LineWrapper>{jobTitle}</LineWrapper>
            <LineWrapper>
              <OfficeBuildingIcon />
              {company}
            </LineWrapper>
          </TwoColumnsMinorInfo>
        </WeekDate>
      );
    case 'timeGridDay':
      return (
        <DayDate>
          <b>
            {formatDate({
              date: start,
              format: 'time',
            })}{' '}
            - {formatDate({ date: end, format: 'time' })}
          </b>
          <TwoColumnsMinorInfo>
            <LineWrapper>{jobTitle}</LineWrapper>
            <LineWrapper>
              <OfficeBuildingIcon />
              {company}
            </LineWrapper>
          </TwoColumnsMinorInfo>
        </DayDate>
      );
    default:
      return (
        <>
          <b>{type}</b> <i>{title}</i>
        </>
      );
  }
}
interface JobsCalendarProps {
  board: Board;
  handleCardDrag: (a: any) => void;
  handleDateSelect: (a: any) => void;
}

type CalendarDateEvent = {
  endStr: string;
  startStr: string;
  start: Date;
  end: Date;
  allDay: boolean;
};

type InputEvent = {
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
};

const MonthDate = styled.div<{ $jobStatus: JobStatus }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px;
  border-left: 4px solid red;
  overflow: hidden;
  ${({ $jobStatus }) => $jobStatus === JobStatus.Wishlist && `border-left-color: ${Colors.ContrastUltraLightest};`};
  ${({ $jobStatus }) => $jobStatus === JobStatus.Applied && `border-left-color: ${Colors.ContrastLightest};`};
  ${({ $jobStatus }) => $jobStatus === JobStatus.Interview && `border-left-color: ${Colors.PrimaryLightest};`};
  ${({ $jobStatus }) => $jobStatus === JobStatus.Offer && `border-left-color: ${Colors.Primary};`};
  ${({ $jobStatus }) => $jobStatus === JobStatus.Closed && `border-left-color: ${Colors.ErrorDark};`};
  ${({ $jobStatus }) => $jobStatus === JobStatus.Accepted && `border-left-color: ${Colors.PrimaryDarkest};`};
`;

const WeekDate = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DayDate = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Open = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const TwoColumnsMinorInfo = styled.div`
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
`;
